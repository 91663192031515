import { toast } from "sonner";
import { useState } from "react";
import { ButtonIconLabel } from "@acdc2/ui/components/ui/button-icon-label";
import { DropdownMenuItem } from "@acdc2/ui/components/ui/dropdown-menu";
import { mdiPencil } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { Label } from "@acdc2/ui/components/ui/label";
import { Input } from "@acdc2/ui/components/ui/input";
import { AccessButton } from "@acdc2/ui/components/ui/access-button";
import {
  DocumentRenameDropdownMenuItemFragment,
  useRenameDocumentMutation,
} from "../client/generated";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@acdc2/ui/components/ui/dialog";

type Props = {
  fragment: DocumentRenameDropdownMenuItemFragment;
};

export default function DocumentRenameDropdownMenuItem({
  fragment,
}: Props): JSX.Element {
  const [newName, setNewName] = useState(fragment.name);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const [renameDocument] = useRenameDocumentMutation();

  const onSubmit = async () => {
    try {
      await renameDocument({
        variables: { documentId: fragment.id, newName },
      });
      setIsOpen(false);
    } catch (error) {
      toast(t("DocumentRenameDropdownMenuItem.dialog.error"), {
        description: new String(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ButtonIconLabel
            path={mdiPencil}
            description={t("DocumentRenameDropdownMenuItem.label")}
          />
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("DocumentRenameDropdownMenuItem.dialog.title")}
          </DialogTitle>
          <DialogDescription className="break-all">
            {t("DocumentRenameDropdownMenuItem.dialog.description", {
              name: fragment.name,
            })}
          </DialogDescription>
        </DialogHeader>
        <Label className="flex flex-col gap-2">
          {t("DocumentRenameDropdownMenuItem.dialog.label")}
          <Input
            placeholder={t("DocumentRenameDropdownMenuItem.dialog.placeholder")}
            value={newName}
            onChange={(event) => setNewName(event.target.value)}
          />
        </Label>
        <DialogFooter>
          <AccessButton
            loading={isLoading}
            disabled={newName.length === 0 || isLoading}
            onClick={onSubmit}
            children={t("DocumentRenameDropdownMenuItem.dialog.button")}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
