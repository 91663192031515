import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";

dayjs.extend(duration);
dayjs.extend(relativeTime);

type Props = {
  date: string;
};

export default function DocumentTableDateCell({ date }: Props): JSX.Element {
  const { i18n } = useTranslation();
  const diff = dayjs().diff(date);
  const humanizedDate = dayjs
    .duration(-diff)
    .locale(i18n.language)
    .humanize(true);

  return (
    <div>
      <span>{humanizedDate}</span>
    </div>
  );
}
