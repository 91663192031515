import SidebarTabButton from "../../shell/SidebarTabButton";
import { mdiVectorCombine } from "@mdi/js";
import { useTranslation } from "react-i18next";

type Props = {
  disabled?: boolean;
};

export default function RegionsEditorSidebarTabButton({
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabButton
      tooltip={t("RegionsEditorSidebarTabButton.tooltip")}
      value="regions-editor-tab"
      iconPath={mdiVectorCombine}
      disabled={disabled}
    />
  );
}
