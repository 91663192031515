import { usePageEditorScreenSuspenseQuery } from "../client/generated";
import { useParams } from "react-router-dom";
import Sidebar from "../shell/Sidebar";
import Scaffold from "../shell/Scaffold";
import MetadataSidebarTabButton from "../editors/metadata/MetadataSidebarTabButton";
import MetadataSidebarTabContent from "../editors/metadata/MetadataSidebarTabContent";
import RegionsEditorSidebarTabButton from "../editors/regions/RegionsEditorSidebarTabButton";
import ReadingOrderSidebarTabButton from "../editors/reading-order/ReadingOrderSidebarTabButton";
import TableEditorSidebarTabButton from "../editors/table/TableEditorSidebarTabButton";
import ChemistryEditorSidebarTabButton from "../editors/chemistry/ChemistryEditorSidebarTabButton";
import ListEditorSidebarTabButton from "../editors/list/ListEditorSidebarTabButton";
import ImageEditorSidebarTabButton from "../editors/image/ImageEditorSidebarTabButton";
import ChemistryEditorSidebarTabContent from "../editors/chemistry/ChemistryEditorSidebarTabContent";
import ImageEditorSidebarTabContent from "../editors/image/ImageEditorSidebarTabContent";
import RegionsEditorSidebarTabContent from "../editors/regions/RegionsEditorSidebarTabContent";
import ReadingOrderSidebarTabContent from "../editors/reading-order/ReadingOrderSidebarTabContent";
import TableEditorSidebarTabContent from "../editors/table/TableEditorSidebarTabContent";
import ListEditorSidebarTabContent from "../editors/list/ListEditorSidebarTabContent";
import MathEditorSidebarTabButton from "../editors/math/MathEditorSidebarTabButton";
import MathEditorSidebarTabContent from "../editors/math/MathEditorSidebarTabContent";
import PagesOverviewSidebarTabButton from "../editors/pages/PagesOverviewSidebarTabButton";
import PagesOverviewSidebarTabContent from "../editors/pages/PagesOverviewSidebarTabContent";
import OverviewSidebarButton from "../editors/OverviewSidebarButton";
import SidebarDivider from "../shell/SidebarDivider";
import PageEditor from "../editors/PageEditor";

export default function PageEditorScreen(): JSX.Element {
  const { documentId, pageId } = useParams<{
    documentId: string;
    pageId: string;
  }>();

  const { data } = usePageEditorScreenSuspenseQuery({
    variables: { documentId: documentId!, pageId: pageId! },
  });

  if (
    data.document?.__typename !== "Document" ||
    data.page?.__typename !== "Page"
  ) {
    throw TypeError("Document or Page not found");
  }

  return (
    <Scaffold
      sidebar={
        <Sidebar
          defaultTabValue="metadata-editor-tab"
          buttons={
            <>
              <OverviewSidebarButton fragment={data.document} />
              <SidebarDivider />
              <MetadataSidebarTabButton />
              <PagesOverviewSidebarTabButton />
              <RegionsEditorSidebarTabButton />
              <TableEditorSidebarTabButton />
              <ListEditorSidebarTabButton />
              <ImageEditorSidebarTabButton />
              <MathEditorSidebarTabButton />
              <ChemistryEditorSidebarTabButton />
              <ReadingOrderSidebarTabButton />
            </>
          }
          content={
            <>
              <MetadataSidebarTabContent />
              <PagesOverviewSidebarTabContent
                fragment={data.document.pages}
                documentId={data.document.id}
              />
              <RegionsEditorSidebarTabContent />
              <TableEditorSidebarTabContent />
              <ListEditorSidebarTabContent />
              <ImageEditorSidebarTabContent />
              <MathEditorSidebarTabContent />
              <ChemistryEditorSidebarTabContent />
              <ReadingOrderSidebarTabContent />
            </>
          }
        />
      }
    >
      <PageEditor fragment={data.page} />
    </Scaffold>
  );
}
