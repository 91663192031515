type Props = {
  children?: React.ReactNode;
  sidebar?: React.ReactNode;
};

export default function Scaffold({ children, sidebar }: Props): JSX.Element {
  return (
    <div className="h-screen flex flex-row">
      <>{sidebar}</>
      <main className="flex-1 flex flex-col overflow-auto">{children}</main>
    </div>
  );
}
