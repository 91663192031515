import Icon from "@mdi/react";
import { Button, ButtonProps } from "./button";
import { mdiLoading } from "@mdi/js";

type Props = ButtonProps & {
  loading?: boolean;
};

export function AccessButton({
  loading,
  children,
  ...props
}: Props): JSX.Element {
  return (
    <Button {...props}>
      {loading ? (
        <Icon
          path={mdiLoading}
          size={1}
          className="mr-2 h-4 w-4 animate-spin"
        />
      ) : null}
      {children}
    </Button>
  );
}
