import { Column } from "@tanstack/react-table";
import { Button } from "@acdc2/ui/components/ui/button";
import { mdiMenuUp, mdiMenuDown, mdiSwapVertical } from "@mdi/js";
import { DocumentRowFragment } from "../client/generated";
import Icon from "@mdi/react";

type Props = {
  column: Column<DocumentRowFragment>;
};

export default function DocumentSortingButton({ column }: Props): JSX.Element {
  return (
    <Button
      size="icon"
      variant="ghost"
      onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    >
      {column.getIsSorted() === "asc" ? (
        <Icon path={mdiMenuDown} size={1} />
      ) : column.getIsSorted() === "desc" ? (
        <Icon path={mdiMenuUp} size={1} />
      ) : (
        <Icon path={mdiSwapVertical} size={1} />
      )}
    </Button>
  );
}
