import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@acdc2/ui/components/ui/tooltip";

type Props = {
  children: React.ReactNode;
  tooltip: string;
};

export default function SidebarButton({
  children,
  tooltip,
}: Props): JSX.Element {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent side="right" className="m-3">
          {tooltip}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
