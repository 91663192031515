import { useTranslation } from "react-i18next";
import SidebarTabContent from "../../shell/SidebarTabContent";

export default function ImageEditorSidebarTabContent(): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabContent
      value="image-editor-tab"
      title={t("ImageEditorSidebarTabContent.title")}
    >
      Image Editor Content
    </SidebarTabContent>
  );
}
