import SidebarTabButton from "../../shell/SidebarTabButton";
import { mdiSwapHorizontalVariant } from "@mdi/js";
import { useTranslation } from "react-i18next";

type Props = {
  disabled?: boolean;
};

export default function ReadingOrderSidebarTabButton({
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabButton
      tooltip={t("ReadingOrderSidebarTabButton.tooltip")}
      value="reading-order-editor-tab"
      iconPath={mdiSwapHorizontalVariant}
      disabled={disabled}
    />
  );
}
