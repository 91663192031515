import SidebarTabButton from "../../shell/SidebarTabButton";
import { mdiFileDocumentOutline } from "@mdi/js";
import { useTranslation } from "react-i18next";

export default function MetadataSidebarTabButton(): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabButton
      tooltip={t("MetadataSidebarTabButton.tooltip")}
      value="metadata-editor-tab"
      iconPath={mdiFileDocumentOutline}
    />
  );
}
