import DocumentsScreen from "./screens/DocumentsScreen";
import PageEditorScreen from "./screens/PageEditorScreen";
import DocumentEditorScreen from "./screens/DocumentEditorScreen";
import LoginScreen from "./screens/LoginScreen";
import SettingsScreen from "./screens/SettingsScreen";
import HelpScreen from "./screens/HelpScreen";
import Shell from "./shell/Shell";
import { useAuth } from "react-oidc-context";
import { Route, Routes } from "react-router-dom";
import { Toaster } from "@acdc2/ui/components/ui/sonner";

export default function App() {
  const auth = useAuth();

  if (!auth.isAuthenticated) {
    return <LoginScreen />;
  }

  return (
    <Shell>
      <Routes>
        <Route index element={<DocumentsScreen />} />
        <Route path="/editor/:documentId" element={<DocumentEditorScreen />} />
        <Route
          path="/editor/:documentId/:pageId"
          element={<PageEditorScreen />}
        />
        <Route path="/settings" element={<SettingsScreen />} />
        <Route path="/help" element={<HelpScreen />} />
      </Routes>
      <Toaster />
    </Shell>
  );
}
