import Sidebar from "../shell/Sidebar";
import Scaffold from "../shell/Scaffold";
import { useTranslation } from "react-i18next";

export default function HelpScreen(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Scaffold sidebar={<Sidebar />}>
      <h1>{t("HelpScreen.title")}</h1>
    </Scaffold>
  );
}
