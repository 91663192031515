import { useTranslation } from "react-i18next";
import SidebarTabContent from "../../shell/SidebarTabContent";

export default function ListEditorSidebarTabContent(): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabContent
      value="list-editor-tab"
      title={t("ListEditorSidebarTabContent.title")}
    >
      List Editor Content
    </SidebarTabContent>
  );
}
