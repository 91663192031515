import Scaffold from "../shell/Scaffold";
import Sidebar from "../shell/Sidebar";
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";
import { useTranslation } from "react-i18next";

export default function LoadingScreen(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Scaffold sidebar={<Sidebar />}>
      <div className="flex-1 flex flex-col justify-center items-center gap-4">
        <Icon
          path={mdiLoading}
          size={2}
          className="animate-spin text-primary"
        />
        <span className="text-lg font-bold uppercase">
          {t("LoadingScreen.label")}
        </span>
      </div>
    </Scaffold>
  );
}
