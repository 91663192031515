import { useTranslation } from "react-i18next";
import SidebarTabContent from "../../shell/SidebarTabContent";

export default function ReadingOrderSidebarTabContent(): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabContent
      value="reading-order-editor-tab"
      title={t("ReadingOrderSidebarTabContent.title")}
    >
      Reading Order Editor Content
    </SidebarTabContent>
  );
}
