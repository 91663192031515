import { Button } from "@acdc2/ui/components/ui/button";
import { ButtonIconLabel } from "@acdc2/ui/components/ui/button-icon-label";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@acdc2/ui/components/ui/dialog";
import { DropdownMenuItem } from "@acdc2/ui/components/ui/dropdown-menu";
import { mdiExport } from "@mdi/js";
import { useTranslation } from "react-i18next";
import DocumentExtensionCombobox from "./DocumentExtensionCombobox";
import { DocumentExportDropdownMenuItemFragment } from "../client/generated";

type Props = {
  fragment: DocumentExportDropdownMenuItemFragment;
};

export default function DocumentExportDropdownMenuItem({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Dialog>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ButtonIconLabel
            path={mdiExport}
            description={t("DocumentExportDropdownMenuItem.label")}
          />
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("DocumentExportDropdownMenuItem.dialog.title")}
          </DialogTitle>
          <DialogDescription className="break-all">
            {t("DocumentExportDropdownMenuItem.dialog.description", {
              name: fragment.name,
            })}
          </DialogDescription>
        </DialogHeader>
        <DocumentExtensionCombobox fragment={fragment.members} />
        <DialogFooter>
          <Button type="submit">
            {t("DocumentExportDropdownMenuItem.dialog.button")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
