import DocumentSortingButton from "./DocumentSortingButton";
import { Column } from "@tanstack/react-table";
import { DocumentRowFragment } from "../client/generated";
import { useTranslation } from "react-i18next";

type Props = {
  column: Column<DocumentRowFragment>;
};

export default function DocumentUploadingTableHeader({
  column,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-1">
      <span>{t("DocumentUploadingTableHeader.label")}</span>
      <DocumentSortingButton column={column} />
    </div>
  );
}
