import { toast } from "sonner";
import { ButtonIconLabel } from "@acdc2/ui/components/ui/button-icon-label";
import { Button } from "@acdc2/ui/components/ui/button";
import { AccessButton } from "@acdc2/ui/components/ui/access-button";
import { mdiFileUploadOutline } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { useAuth } from "react-oidc-context";
import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { DocumentsScreenDocument } from "../client/generated";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@acdc2/ui/components/ui/dialog";

export default function DocumentUploadButton(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const { t } = useTranslation();
  const auth = useAuth();
  const apolloClient = useApolloClient();

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      multiple: true,
      onDrop: setAcceptedFiles,
    });

  const uploadDocument = async (file: File) => {
    const data = new FormData();
    data.append("file", file);
    data.append("fileName", file.name);

    await fetch(import.meta.env.VITE_ACDC2_FILE_API_URL, {
      method: "POST",
      body: data,
      headers: { Authorization: `Bearer ${auth.user?.access_token}` },
    });
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const bodies = acceptedFiles.map((file) => uploadDocument(file));

    try {
      await Promise.all(bodies);
    } catch (error) {
      toast(t("DocumentUploadButton.dialog.error"), {
        description: new String(error),
      });
    }

    try {
      await apolloClient.refetchQueries({
        include: [DocumentsScreenDocument],
      });
    } catch (error) {
      console.error("Failed to refetch queries", error);
    } finally {
      setIsLoading(false);
      setAcceptedFiles([]);
      setIsOpen(false);
    }
  };

  const onOpenChange = (newIsOpen: boolean) => {
    setAcceptedFiles([]);
    setIsOpen(newIsOpen);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button>
          <ButtonIconLabel
            path={mdiFileUploadOutline}
            description={t("DocumentUploadButton.label")}
          />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("DocumentUploadButton.dialog.title")}</DialogTitle>
        </DialogHeader>
        <div
          {...getRootProps()}
          className="bg-gray-50 border border-dashed cursor-pointer rounded min-h-32 flex justify-center items-center p-4"
        >
          <input {...getInputProps()} />
          {isDragAccept ? (
            <p>{t("DocumentUploadButton.dialog.dropzone.accept")}</p>
          ) : isDragReject ? (
            <p>{t("DocumentUploadButton.dialog.dropzone.reject")}</p>
          ) : acceptedFiles.length > 0 ? (
            <ol className="list-disc list-inside break-all">
              {acceptedFiles.map((file) => (
                <li>{file.name}</li>
              ))}
            </ol>
          ) : (
            <p>{t("DocumentUploadButton.dialog.dropzone.select")}</p>
          )}
        </div>
        <DialogFooter>
          <AccessButton
            loading={isLoading}
            disabled={acceptedFiles.length === 0 || isLoading}
            onClick={onSubmit}
            children={t("DocumentUploadButton.dialog.button")}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
