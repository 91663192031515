import SidebarTabContent from "../../shell/SidebarTabContent";
import { useTranslation } from "react-i18next";

export default function MathEditorSidebarTabContent(): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabContent
      value="math-editor-tab"
      title={t("MathEditorSidebarTabContent.title")}
    >
      Math Editor Content
    </SidebarTabContent>
  );
}
