import { useTranslation } from "react-i18next";
import { useDocumentsScreenSuspenseQuery } from "../client/generated";
import Sidebar from "../shell/Sidebar";
import Scaffold from "../shell/Scaffold";
import DocumentTable from "../documents/DocumentTable";

export default function DocumentsScreen(): JSX.Element {
  const { t } = useTranslation();
  const { data } = useDocumentsScreenSuspenseQuery();

  return (
    <Scaffold sidebar={<Sidebar />}>
      <div className="container mx-auto my-12 gap-8 flex flex-col">
        <h1
          className="text-4xl text-center font-semibold"
          data-testid="documents-screen-heading"
        >
          {t("DocumentsScreen.title")}
        </h1>
        <DocumentTable fragment={data.viewer.memberships} />
      </div>
    </Scaffold>
  );
}
