import { useTranslation } from "react-i18next";
import SidebarTabContent from "../../shell/SidebarTabContent";

export default function MetadataSidebarTabContent(): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabContent
      value="metadata-editor-tab"
      title={t("MetadataSidebarTabContent.title")}
    >
      Metadata editor content
    </SidebarTabContent>
  );
}
