import { setContext } from "@apollo/client/link/context";
import { createHttpLink } from "@apollo/client";
import { User } from "oidc-client-ts";

const getUser = () => {
  const authority = import.meta.env.VITE_ACDC2_OIDC_AUTHORITY;
  const clientId = import.meta.env.VITE_ACDC2_OIDC_CLIENT_ID;
  const key = `oidc.user:${authority}:${clientId}`;
  const oidcStorage = window.localStorage.getItem(key);

  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
};

export const authLink = setContext((_, { headers }) => {
  const user = getUser();
  const token = user?.access_token;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const httpLink = createHttpLink({
  uri: import.meta.env.VITE_ACDC2_GRAPHQL_URL,
});
