import { useState } from "react";
import {
  ColumnFiltersState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  DocumentRowFragment,
  DocumentTableFragment,
} from "../client/generated";
import { Link } from "react-router-dom";
import DocumentUploadButton from "./DocumentUploadButton";
import DocumentUploaderHeader from "./DocumentUploaderTableHeader";
import DocumentTitleTableHeader from "./DocumentTitleTableHeader";
import DocumentModificationTableHeader from "./DocumentModificationTableHeader";
import DocumentExtensionTableHeader from "./DocumentExtensionTableHeader";
import DocumentModifierTableHeader from "./DocumentModifierTableHeader";
import DocumentTableFilters from "./DocumentTableFilters";
import DocumentTableContent from "./DocumentTableContent";
import DocumentUploadingTableHeader from "./DocumentUploadingTableHeader";
import DocumentTableDateCell from "./DocumentTableDateCell";
import DocumentTableActionsDropdownMenu from "./DocumentTableActionsDropdownMenu";

type Props = {
  fragment: DocumentTableFragment;
};

const columnHelper = createColumnHelper<DocumentRowFragment>();

export default function DocumentTable({ fragment }: Props): JSX.Element {
  const [sorting, setSorting] = useState<SortingState>([
    { id: "lastModifiedAt", desc: true },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    data: fragment.memberships,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    enableSortingRemoval: true,
    state: { sorting, columnFilters },
  });

  return (
    <div>
      <div className="flex justify-between items-center py-4 gap-4">
        <DocumentUploadButton />
        <DocumentTableFilters table={table} />
      </div>
      <DocumentTableContent table={table} />
    </div>
  );
}

const columns = [
  columnHelper.accessor("document.name", {
    id: "name",
    header: ({ column }) => <DocumentTitleTableHeader column={column} />,
    cell: (cellContext) => (
      <Link to={`/editor/${cellContext.row.original.document.id}`}>
        {cellContext.row.original.document.name}
      </Link>
    ),
  }),

  columnHelper.accessor("document.uploadedBy.username", {
    id: "ownerUsername",
    header: ({ column }) => <DocumentUploaderHeader column={column} />,
  }),

  columnHelper.accessor("document.uploadedAt", {
    header: ({ column }) => <DocumentUploadingTableHeader column={column} />,
    cell: (cellContext) => (
      <DocumentTableDateCell
        date={cellContext.row.original.document.uploadedAt}
      />
    ),
  }),

  columnHelper.accessor("document.lastModifiedBy.username", {
    header: ({ column }) => <DocumentModifierTableHeader column={column} />,
  }),

  columnHelper.accessor("document.lastModifiedAt", {
    id: "lastModifiedAt",
    header: ({ column }) => <DocumentModificationTableHeader column={column} />,
    cell: (cellContext) => (
      <DocumentTableDateCell
        date={cellContext.row.original.document.lastModifiedAt}
      />
    ),
  }),

  columnHelper.accessor("document.extension", {
    id: "extension",
    header: ({ column }) => <DocumentExtensionTableHeader column={column} />,
  }),

  columnHelper.display({
    id: "actions",
    cell: ({ row }) => (
      <DocumentTableActionsDropdownMenu fragment={row.original.document} />
    ),
  }),
];
