import { ButtonIconLabel } from "@acdc2/ui/components/ui/button-icon-label";
import { mdiHelpCircle } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { DropdownMenuItem } from "@acdc2/ui/components/ui/dropdown-menu";
import { Link } from "react-router-dom";

export default function ViewerHelpDropdownMenuItem(): JSX.Element {
  const { t } = useTranslation();

  return (
    <DropdownMenuItem>
      <Link to="/help">
        <ButtonIconLabel
          path={mdiHelpCircle}
          description={t("ViewerHelpDropdownMenuItem.label")}
        />
      </Link>
    </DropdownMenuItem>
  );
}
