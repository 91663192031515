import SidebarTabButton from "../../shell/SidebarTabButton";
import { mdiFileMultipleOutline } from "@mdi/js";
import { useTranslation } from "react-i18next";

type Props = {
  disabled?: boolean;
};

export default function PagesOverviewSidebarTabButton({
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabButton
      tooltip={t("PagesOverviewSidebarTabButton.tooltip")}
      value="pages-overview-tab"
      iconPath={mdiFileMultipleOutline}
      disabled={disabled}
    />
  );
}
