import SidebarTabContent from "../../shell/SidebarTabContent";
import { useTranslation } from "react-i18next";

export default function ChemistryEditorSidebarTabContent(): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabContent
      value="chemistry-editor-tab"
      title={t("ChemistryEditorSidebarTabContent.title")}
    >
      Chemistry Editor Content
    </SidebarTabContent>
  );
}
