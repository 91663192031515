import { UserManager, WebStorageStateStore } from "oidc-client-ts";

export const userManager = new UserManager({
  authority: import.meta.env.VITE_ACDC2_OIDC_AUTHORITY,
  client_id: import.meta.env.VITE_ACDC2_OIDC_CLIENT_ID,
  redirect_uri: import.meta.env.VITE_ACDC2_OIDC_REDIRECT_URI,
  scope: import.meta.env.VITE_ACDC2_OIDC_SCOPE,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  monitorSession: true,
});

export const onSigninCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};
