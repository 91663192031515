import { PageFragment } from "../client/generated";

type Props = {
  fragment: PageFragment;
};

export default function Page({ fragment }: Props): JSX.Element {
  return (
    <div className="h-screen p-4">
      <div className="h-full aspect-[1/1.414] bg-white shadow">
        Page {fragment.index}
      </div>
    </div>
  );
}
