import { Badge } from "@acdc2/ui/components/ui/badge";
import { PageThumbnailFragment } from "../../client/generated";
import { Link } from "react-router-dom";

type Props = {
  fragment: PageThumbnailFragment;
  documentId: string;
};

export default function PageThumbnail({
  fragment,
  documentId,
}: Props): JSX.Element {
  return (
    <Link to={`/editor/${documentId}/${fragment.id}`}>
      <div className="aspect-[1/1.414] border w-full flex relative">
        <div className="bg-slate-200 dark:bg-slate-800 w-full h-full absolute animate-pulse"></div>
        <div className="p-4 absolute bottom-0 right-0">
          <Badge>{fragment.index}</Badge>
        </div>
      </div>
    </Link>
  );
}
