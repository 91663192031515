import SidebarTabButton from "../../shell/SidebarTabButton";
import { mdiImageOutline } from "@mdi/js";
import { useTranslation } from "react-i18next";

type Props = {
  disabled?: boolean;
};

export default function ImageEditorSidebarTabButton({
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabButton
      tooltip={t("ImageEditorSidebarTabButton.tooltip")}
      value="image-editor-tab"
      iconPath={mdiImageOutline}
      disabled={disabled}
    />
  );
}
