import { useTranslation } from "react-i18next";
import SidebarTabContent from "../../shell/SidebarTabContent";

export default function RegionsEditorSidebarTabContent(): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabContent
      value="regions-editor-tab"
      title={t("RegionsEditorSidebarTabContent.title")}
    >
      Regions Editor Content
    </SidebarTabContent>
  );
}
