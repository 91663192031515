import SidebarButton from "./SidebarButton";
import SidebarButtonIcon from "./SidebarButtonIcon";
import { mdiWeb } from "@mdi/js";
import { useTranslation } from "react-i18next";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@acdc2/ui/components/ui/dropdown-menu";

export default function ViewerLocaleButton(): JSX.Element {
  const { t, i18n } = useTranslation();

  const onValueChange = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem("i18nextLng", language);
  };

  return (
    <DropdownMenu>
      <SidebarButton tooltip={t("ViewerLocaleButton.title")}>
        <DropdownMenuTrigger asChild>
          <SidebarButtonIcon path={mdiWeb} />
        </DropdownMenuTrigger>
      </SidebarButton>
      <DropdownMenuContent
        className="my-2"
        side="right"
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        <DropdownMenuLabel>{t("ViewerLocaleButton.title")}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuRadioGroup
          value={i18n.language}
          onValueChange={onValueChange}
        >
          <DropdownMenuRadioItem
            value="de"
            children={t("ViewerLocaleButton.items.de")}
          />
          <DropdownMenuRadioItem
            value="en"
            children={t("ViewerLocaleButton.items.en")}
          />
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
