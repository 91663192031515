import { ErrorBoundary } from "@sentry/react";
import { Suspense } from "react";
import ErrorScreen from "../screens/ErrorScreen";
import LoadingScreen from "../screens/LoadingScreen";

type Props = {
  children?: React.ReactNode;
};

export default function Shell({ children }: Props): JSX.Element {
  return (
    <div className="antialiased">
      <ErrorBoundary fallback={(props) => <ErrorScreen {...props} />}>
        <Suspense fallback={<LoadingScreen />}>{children}</Suspense>
      </ErrorBoundary>
    </div>
  );
}
