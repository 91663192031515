import SidebarTabButton from "../../shell/SidebarTabButton";
import { mdiFormatListBulletedSquare } from "@mdi/js";
import { useTranslation } from "react-i18next";

type Props = {
  disabled?: boolean;
};

export default function ListEditorSidebarTabButton({
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabButton
      tooltip={t("ListEditorSidebarTabButton.tooltip")}
      value="list-editor-tab"
      iconPath={mdiFormatListBulletedSquare}
      disabled={disabled}
    />
  );
}
