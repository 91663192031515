import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@acdc2/ui/components/ui/alert-dialog";
import { ButtonIconLabel } from "@acdc2/ui/components/ui/button-icon-label";
import { DropdownMenuItem } from "@acdc2/ui/components/ui/dropdown-menu";
import { mdiTrashCan } from "@mdi/js";
import { useTranslation } from "react-i18next";
import {
  DocumentDeleteDropdownMenuItemFragment,
  useDeleteDocumentMutation,
} from "../client/generated";

type Props = {
  fragment: DocumentDeleteDropdownMenuItemFragment;
};

export default function DocumentDeleteDropdownMenuItem({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [deleteDocument] = useDeleteDocumentMutation({
    variables: { id: fragment.id },
    update(cache) {
      const id = cache.identify(fragment);
      cache.evict({ id });
      cache.gc();
    },
  });

  const onClick = async () => {
    try {
      await deleteDocument();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ButtonIconLabel
            path={mdiTrashCan}
            description={t("DocumentDeleteDropdownMenuItem.label")}
          />
        </DropdownMenuItem>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t("DocumentDeleteDropdownMenuItem.alert.title")}
          </AlertDialogTitle>
          <AlertDialogDescription className="break-all">
            {t("DocumentDeleteDropdownMenuItem.alert.description", {
              name: fragment.name,
            })}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            {t("DocumentDeleteDropdownMenuItem.alert.buttons.cancel")}
          </AlertDialogCancel>
          <AlertDialogAction onClick={onClick}>
            {t("DocumentDeleteDropdownMenuItem.alert.buttons.confirm")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
