import { PageEditorFragment } from "../client/generated";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Page from "./Page";
import PageMinimap from "./PageMinimap";

type Props = {
  fragment: PageEditorFragment;
};

export default function PageEditor({ fragment }: Props): JSX.Element {
  return (
    <TransformWrapper
      centerOnInit
      doubleClick={{ step: 1 }}
      panning={{ allowRightClickPan: false, allowMiddleClickPan: false }}
    >
      <PageMinimap fragment={fragment} />
      <TransformComponent wrapperClass="!w-full !h-full bg-gray-100 dark:bg-gray-900">
        <Page fragment={fragment} />
      </TransformComponent>
    </TransformWrapper>
  );
}
