import { ButtonIconLabel } from "@acdc2/ui/components/ui/button-icon-label";
import { DropdownMenuItem } from "@acdc2/ui/components/ui/dropdown-menu";
import { mdiLogin } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

export default function ViewerLoginDropdownMenuItem(): JSX.Element {
  const { t } = useTranslation();
  const auth = useAuth();

  const onClick = () => {
    auth.signinRedirect();
  };

  return (
    <DropdownMenuItem onClick={onClick}>
      <ButtonIconLabel
        path={mdiLogin}
        description={t("ViewerLoginDropdownMenuItem.label")}
      />
    </DropdownMenuItem>
  );
}
